import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/weio_blog_header.png");
const section_1 = require("../../../assets/img/blogs/weio_img_1.png");
const section_2 = require("../../../assets/img/blogs/weio_img_2.png");
const section_3 = require("../../../assets/img/blogs/weio_img_3.png");
const section_4 = require("../../../assets/img/blogs/weio_img_4.png");
const section_5 = require("../../../assets/img/blogs/weio_img_5.png");
const section_6 = require("../../../assets/img/blogs/weio_img_6.png");
const section_7 = require("../../../assets/img/blogs/weio_img_7.png");
const section_8 = require("../../../assets/img/blogs/weio_img_8.png");
const section_9 = require("../../../assets/img/blogs/weio_img_9.png");
const section_10 = require("../../../assets/img/blogs/weio_img_10.png");

const reviewer_image = require("../../../assets/images/testimonials/reviewer_circle.png");
const hammer_manager_mobile = require("../../../assets/images/testimonials/reviewer_circle.png");
const hammer_logo = require("../../../assets/images/testimonials/hammer_logo.svg");
const star_image = require("../../../assets/images/testimonials/star.svg");
const capterra_logo = require("../../../assets/images/testimonials/capterra_logo.svg");
const quotes = require("../../../assets/images/testimonials/quotes.svg");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Cost benefits of generative ai service desk"
        description="Discover how generative AI streamlines service desk operations, driving cost savings while enhancing user satisfaction."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt weio_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <>
                    <h1 className="font-page-header-blog-gen-ai color-black">
                      Cost Benefits of Generative AI:
                    </h1>
                    <h2 className="font-page-header-blog-gen-ai-normal color-black">
                      A deep dive into the{" "}
                      <span
                        style={{ backgroundColor: "#FFFA7E" }}
                        className="padding-14px"
                      >
                        economic impact
                      </span>{" "}
                      of Gen AI into service desk teams
                    </h2>
                    <button className="ebook-button-blog font-section-sub-header-small color-white">
                      Read ebook below!
                    </button>
                  </>
                ) : (
                  <>
                    <h1 className="font-page-header-blog-gen-ai color-black">
                      Cost Benefits of Generative AI:
                    </h1>
                    <h2 className="font-page-header-blog-gen-ai-normal color-black">
                      A deep dive into the{" "}
                      <span
                        style={{ backgroundColor: "#FFFA7E" }}
                        className="padding-14px"
                      >
                        economic impact
                        <br />
                      </span>{" "}
                      of Gen AI into service desk teams
                    </h2>
                    <button
                      className="ebook-button-blog font-section-sub-header-small color-white"
                      onClick={() => {}}
                    >
                      Read ebook below!
                    </button>
                  </>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. The current state of employee support (challenges)
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Conversational AI X Generative AI-powered chat interface
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Key Features and Capabilities of Workativ
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. The economic impact of Workativ on organizations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Analysis of cost savings
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Workativ's contribution to modernizing support services.
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Workativ cost analysis
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Now and then, employees need information to get work done.
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search?trk=article-ssr-frontend-pulse_little-text-block">
                  Enterprise search for knowledge discovery
                </a>{" "}
                and access is imperative for businesses of all sizes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service or help desks are most organizations' sole platform for
                finding information. Unfortunately, broken or unsophisticated
                technologies make finding information difficult for users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A legacy{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk
                </a>{" "}
                is at the root of this problem, needing more essential features
                to help with information discovery and access for employees to
                work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise search and knowledge discovery are inconsistent with
                most organizations' standards, forcing employees to struggle
                with the most common IT or HR support issues. So, it is common
                to have operational efficiency and employee disengagement
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ’s conversational AI,
                </a>{" "}
                which combines the features of Generative AI, facilitates faster
                collaborations and communications for knowledge discovery with
                great flexibility and helps improve operations efficiency and
                business resilience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Our eBook emphasizes the economic impact of your investment in
                conversational AI in the long run and better cost optimization,
                which you otherwise fail to realize with the legacy approach,
                incurring higher maintenance costs and productivity losses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To help you understand the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise-pros-cons">
                  {" "}
                  benefits of conversational AI and Generative AI
                </a>{" "}
                for employee productivity and operational efficiency, our
                comprehensive analysis of costs being wasted towards maintaining
                a legacy system gives you a clear understanding of where you are
                spending extra money, yet not leveraging benefits as your
                business needs to grow.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ as a conversational AI is a significant impetus for
                enterprise needs to drive operational efficiency through
                improved employee communications and collaborations, helping
                them find crucial daily information to get work and reduce the
                cost of managing service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left mb-10" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                The current state of employee support (challenges)
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity losses and bottom-line implications driven
                by ticketing systems or old and legacy service desk systems are
                often overlooked by business leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses have disparate tools placed at different touchpoints
                for their employees to enable them to raise service or help
                requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Should requests have real-time responses, employees can expect
                rapid resolution of issues and increased uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Incognizance of the efficacy of conversational AI and
                self-service preferences for traditional ITSM tools does not
                help but throw up more challenges.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Inefficient communication results in a 5-hour loss during a
                  40-hour working week. The{" "}
                  <a href="https://www.mitel.com/-/media/mitel/file/pdf/resource-center/en-wp-2019-workplace-productivity-report.pdf">
                    Workplace Productivity and Communications Technology Report
                  </a>{" "}
                  conducted by Mittel and Kelcor in five countries on 2,500
                  business professionals observed that
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Nearly 15% of employees' time at work is wasted in a day,
                  constituting about 1 hour of loss every day and 5 hours a
                  week. The productivity loss occurs for the want of efficient
                  communications systems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Inefficiency in communications costs the employers $10,200
                  annually per worker.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                The cost of employee productivity loss or downtime companies
                experience happens for inefficient tools. It is a double blow on
                the company's balance sheet.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Productivity losses or downtime costs
                </li>
                <li className="font-section-normal-text-testimonials">
                  The cost incurred to maintain multiple communication tools
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                Though the report involves unified communications systems, we
                can relate this to an inefficient service desk or a traditional
                service desk operations management that hinges upon emails,
                phone calls, or intranets. Being multichannel and not
                omnichannel, these disparate tools do not provide integrated
                views of either ticket escalation or lifecycle management,
                causing rising maintenance costs for HR and IT support and
                leading to employee dissatisfaction.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Cost per ticket (CPT) is rising for businesses for service
                  desks unable to handle scalability. It means a legacy model
                  can increase the volume of tickets for incident and service
                  requests when they fail first contact resolution (FCR). It can
                  happen when manual processes are involved in ticket handling.
                  Lack of tools and systems tier 1 agent use may be old and
                  inefficient to handle tickets and causes ticket escalation to
                  the next tier, raising ticket costs.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                In reality, service requests are a lengthy process compared to
                incident requests, which combine to add to a weighted average
                cost per ticket.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Since employees prefer service requests, an unanswered or
                  delayed service ticket can quickly increase ticket costs and
                  cause productivity losses. ThinkHDI claimed in its report that
                  service requests cost more than incident requests. If
                  employees need to wait for a software upgrade, a new system
                  allocation, and a password reset, a{" "}
                  <a href="https://www.thinkhdi.com/~/media/HDICorp/Files/Library-Archive/Insider%20Articles/Cost%20per%20Ticket.pdf">
                    company has to pay more for a ticket.
                  </a>{" "}
                  For example, if XYZ company handles 60% of service requests
                  and 40% of incident tickets, the weighted average cost per
                  ticket would be higher than the company handling incident
                  tickets in more volumes.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The legacy infrastructure of a service desk model follows a
                  break/fix approach and offers help only when there is a
                  problem. It results in tickets with the same issue being
                  raised to the service desks and agents addressing them
                  repeatedly. From that perspective, a legacy model is more of a
                  reactive platform than a proactive one.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Modern service desks offer self-serve capabilities, yet they
                  are manual and agent-dependent. Even though a modern service
                  desk combines the power of artificial intelligence to allow
                  employees or agents to automate ticket creation, it does not
                  offer self-service help instantly. It is only as good as the
                  legacy service desk that enables employees to create tickets
                  and wait for the agent to hop in and provide help.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The wait time for a ticket increases, causing high costs for
                  each ticket.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 list-style-type-square font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The average cost for tickets addressed by voice is $17.19.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chat ticket support costs $15.72 on average.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email support costs $16.13 on average.
                </li>
              </ul>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                The{" "}
                <a href="https://www.thinkhdi.com/library/supportworld/2021/understanding-cost-per-ticket">
                  figure
                </a>{" "}
                above describes the benchmarking ranges for CPT for North
                American Service Desks.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  If agents for specific channel support are absent, the
                  escalation can move to the next channel support, increasing
                  the costs per ticket.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manual interventions are more likely to involve errors and
                  repetitive ticket handling of the exact service requests,
                  causing productivity losses for agents and employees.
                </li>
              </ul>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Conversational AI X Generative AI-powered chat interface
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Elimination of manual intervention for service requests and
                fostering operational efficiency at scale
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Manual help for problem-solving can be intimidating because the
                assistance employees want may not turn up on a particular day.
                Unfortunately, it can take another day or two for the help to
                come.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                  Auto-resolutions of service requests
                </a>{" "}
                through conversational AI and Generative AI are productive and
                transformative for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A conversational AI and Generative AI solution can define a
                chatbot platform as more sophisticated and advanced than a
                regular chatbot. It gives answers to natural language queries by
                detecting the context and intent of a query rather than
                answering from predefined conversation templates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ redefines workplace automation for common employee
                queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI combines Generative AI in its
                interface for users to make finding information fast and quick
                without needing additional effort to read through long articles.
                It also provides real-time auto-resolution capabilities for
                users to solve problems instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                  empower employees
                </a>{" "}
                to surface information regarding common service requests and
                resolve them at scale while ensuring uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Answers to common employee questions such as password reset,
                account unlock, VPN settings, and many more are real-time and
                autonomous for employees who need no human interventions or wait
                for the ticket to escalate to move to the next tier for
                resolution.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee flexibility on Workativ
              </h3>
              <img src={section_2} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  An integrated service desk solution is Workativ’s significant
                  flexibility for employees who no longer need to interact
                  through a service desk platform, create a ticket, and wait for
                  the agent to provide help. Workativ provides{" "}
                  <a href="https://workativ.com/assistant/workflow-automation">
                    app workflow automation
                  </a>{" "}
                  for service desk platforms and allows users to sync with Teams
                  or Slack to auto-resolve issues through LLM-powered Knowledge
                  AI search flexibility or custom FAQs.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employees can have an omnichannel-like compact experience by
                  leveraging a unified conversational AI interface, eliminating
                  the need to seek service requests or help via emails, voice
                  calls, or complicated intranets. Workativ’s conversational AI
                  is the perfect example of a self-serve or autonomous help
                  interface to simplify problem-solving through pre-defined IT
                  or HR support scenarios or custom queries using deep neural
                  networks or large language model search capability.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Seamless knowledge discovery through Knowledge AI integration
                  ensures employees find answers to a question easily on the
                  Workativ Conversational AI interface and can work quickly.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  Change management is fast with Workativ conversational AI that
                  feels more familiar and easier for employees to adapt to a
                  whole new experience and leverage it to capture information
                  for common questions at the workplace. This seamless change
                  management improves employee problem-solving at scale and
                  increases employee productivity.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Organization flexibility with Workativ
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  There are no higher overheads for organizations, for they take
                  advantage of a{" "}
                  <a href="https://workativ.com/">
                    no-code SAAS-based cloud platform,
                  </a>{" "}
                  which requires no additional costs for infrastructure
                  maintenance similar to what is associated with a total cost of
                  ownership.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ encourages faster time to market. Organizations
                  should only consider the right development of conversation
                  flows for HR and IT support use cases, not the implementation
                  and deployment in the live environment. Workativ does not
                  require a steep learning curve, critical technical abilities,
                  or coding skills to work with its conversational or Generative
                  AI platform. The deployment can easily take place with a few
                  details, workflow automation syncs, and integrations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                    {" "}
                    Seamless app integrations
                  </a>{" "}
                  or app chatbot development for service desk platforms or ITSM
                  tools, Identity Access Management platforms, project
                  management tools, CRM, ERP, etc., are highly effective in
                  creating chatbots for particular apps and take control of
                  functions through familiar collaborations and communications
                  channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Ceaseless connections across value chains give organizations
                  the best ability to keep everyone in the loop, such as
                  business leaders, managers, employees, and other stakeholders,
                  and help keep track of how employee productivity is managed,
                  service delivery is taken care of, and what should be the
                  right steps be taken to improve any inefficiency across the
                  touchpoints of employee interaction and information delivery.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Key Features and Capabilities of Workativ
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Exploring Workativ’s cutting-edge technology
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  {" "}
                  Workativ is built on the technological advancement of
                  Artificial Intelligence, Machine Learning, Large Language
                  Models, or Generative AI.
                </a>{" "}
                Workativ is not limited to predefined conversations; it can
                answer any questions.
              </p>
              <img src={section_4} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The conversational AI platform brings natural language
                  processing or NLP engine, chatbot builder, and dialog
                  management modules to help users build complex to simple
                  dialog flow or conversations for employee queries such as
                  printer issues, search documents, unlock accounts, password
                  reset, etc.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The NLP module leverages intent detection and entity
                  extraction to efficiently execute tasks or workflows no matter
                  if a user’s NLP query is simple or complicated.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multiple intent detection can easily handle multiple requests
                  within a single conversation flow.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Auto-corrections and spelling checks let users surface the
                  correct answer even if the query includes spelling errors.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Context switching is more valuable and effective if users want
                  to switch context midway through the conversion and still
                  surface contextual answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Disambiguation helps improve and reduce ambiguity and provide
                  clarity in conversation.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Chatbot automation helps users automate app workflows,
                  integrate with apps, and facilitate approvals. It also allows
                  setting alerts for apps to remind users of necessary actions
                  to perform.
                </li>
                <li className="font-section-normal-text-testimonials">
                  KnowledgeAI provides a large language model capability to help
                  build a{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                    ChatGPT
                  </a>
                  -like dialog channel that turns your knowledge into Generative
                  AI answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Live agent handover is critical to advancing a conversation
                  when it is repeated or has no answer in its database.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chatbot analytics and performance give a single pane of glass
                  view into bot session, performance, failed calls, etc.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                All these features and technology advancements have a solid
                focus for Workativ that aims to enrich the user experience by
                improving problem-solving for everyday workplace issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The economic impact of Workativ on organizations
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee engagement and cost savings enabled by Workativ
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ's conversational AI platform aims to help businesses
                introduce digital transformation or modernize their current
                processes, which are otherwise unattainable due to the high
                costs associated with tools and technologies and critical
                digital transformation barriers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a no-code chatbot builder, AI or Generative AI engine,
                prebuilt conversation templates, easy app integrations, and
                other significant features, Workativ helps businesses streamline
                processes through automation, remove manual dependency and
                continuous repetitive task handling and costly mistakes, and
                promote a collaborative culture to build a thriving business by
                enhancing employee experience and engagement and customer
                service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ continues to provide scale to employee support no
                matter how many employees increase through its real-time
                knowledge discovery service for autonomous problem solving for
                businesses. High-tech firms, IT consultancy firms, manufacturing
                firms, sports goods-producing firms, and many others have
                brought efficient and effective modernization to their existing
                processes and automate for true digital transformation at half a
                cost.
              </p>
              <HammerReview />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To understand how your investment into Workativ can turn out to
                be effective for your bottom-line investment, analysis of
                benefits and cost-savings is a critical factor.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Quantified benefits{" "}
              </h3>
              <h4 className="font-section-normal-text-medium color-black">
                HR operations benefits
              </h4>
              <img src={section_5} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  5X lower MTTR boosts cost optimization for HR operations. Even
                  a simple request comprising ‘I want to apply for leave’ or ‘I
                  want to know my salary structure’ can take about 20 minutes to
                  4 hours for an HR personnel. Workativ helps you automate most
                  of your employee HR inquiries and requests using an advanced
                  HR Chatbot with in-built HR workflow automation.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees do not need to write to HR. Automated workflows with
                  known and standard HR use cases can be useful for them to ask
                  a direct question to the conversational AI or Generative AI
                  bot from Workativ and get a direct answer.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simple and fast adoption of the Workativ chatbot allows
                  employees to autonomously reduce common HR requests by
                  themselves and instantly reduce the number of requests to the
                  IT service desks.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  90% first contact resolution (FCR) saves HR operational costs
                  by restricting the ticket escalation to the next tier, such as
                  level 2 or 3, helping solve HR requests at level 1, and
                  encouraging time-saving of up to 3 hours.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Human resources can save massive resources, freeing senior HR
                  executives from everyday administrative tasks and helping them
                  handle more critical organizational tasks to provide an
                  excellent employee experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  With the flexibility of self-service functionality, employees
                  can automate their queries and resolve issues, helping HR work
                  more effectively and efficiently driving cost savings and
                  revenue growth.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                IT operations benefits
              </h4>
              <img src={section_6} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  80% of repetitive IT support can be automated using Workativ
                  conversational AI and Generative AI. Service desk or IT help
                  desk teams can remain calm while addressing critical IT issues
                  and keep themselves out of the most mundane and repetitive
                  common IT issues employees raise. Workativ makes it easy for
                  the service desk or help desk users to handle IT support
                  issues autonomously for service requests such as resetting
                  passwords, unlocking accounts, adding users to groups, asset
                  requests, employee onboarding, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  There are 3 hrs to 3.5 hrs of time savings for organizations.
                  The time-saving experience for IT support teams is similar to
                  that of HR teams with Workativ. The duration of handling every
                  ticket for common IT support requests that take up to 20
                  minutes to 4 hours, as per simplicity or complexity of the
                  ticket’s attributes, can be dramatically reduced to less than
                  1 minute. It means service desk or help desk agents can save
                  time up to 3 hrs - 3.5 hours per ticket.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ allows cost savings of up to 5X for FCR tickets
                  through appropriate optimization of agent utilization. Every
                  minute of time-saving constitutes excellent cost savings for
                  the bottom-line expenses of an organization that predominantly
                  needs to allocate a budget for service desk management. The
                  benefit of Workativ for organizations lies in an appropriate
                  optimization of agent utilization, helping organizations save
                  3546 agent hours annually, which further saves organizations
                  costs up to 5X for FCR tickets.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Service desk agents can achieve 3X productivity boosts while
                  handling employee support issues. Workativ provides
                  flexibility in harnessing Knowledge AI capabilities or
                  Generative AI features to enhance knowledge discovery and help
                  find information at scale autonomously that resolves problems
                  at scale and removes the need to raise tickets to the agents.
                  This gives agents and employees a 3X boost in productivity by
                  reducing downtime impacts.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  {" "}
                  Workativ has collaborated with GoTo,
                </a>{" "}
                an IT and software solutions provider company, to help transform
                their employee support through conversational AI and Generative
                AI helpdesk automation chatbot.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
              <h3 className="font-section-sub-header-small-home">
                Unquantified benefits
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Increased employee engagement and productivity gains are two
                  of the key unquantified benefits of Workativ for
                  organizations. Employees are more at ease in solving their
                  problems and less likely to involve a service desk agent in
                  solving common and everyday IT support issues. Employees no
                  longer need to suffer productivity issues of system downtime
                  software upgrade requirements, etc. Similarly, service desk
                  agents can have more time to focus on critical business
                  objectives such as handling IT operations, asset management,
                  etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Everyone at work can seamlessly gain elevated user experience.
                  It’s an amazing experience for value chains across every
                  department. Employees can get an answer easily using advanced
                  LLM-powered knowledge discovery in the service desk automation
                  chatbot and continue their work progress. Agents effortlessly
                  use LLM-powered chatbots to see conversation history and gain
                  context to add personalization to users’ IT or HR support
                  issues. Also, business leaders in their high-level leadership
                  roles, such as CEO, CFO, CIO, or senior-level executives, gain
                  business benefits through employee productivity gains that
                  bring excellent customer service delivery and gain customer
                  trust.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ offers multimodal support for all departments beyond
                  HR, and IT use cases. Finance, Legal, Operations, Marketing,
                  and Sales can derive workflow automation benefits for a wide
                  variety of use cases to remove manual dependency and
                  streamline work processes through automation.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Analysis of cost savings
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Comparing ROI with and without Workativ
              </h3>
              <img src={section_7} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say the total headcount of your organization is 3000 and the
                number of monthly tickets is 5000. Out of 20 service desk
                agents, 10 handle L1 tickets, 7 handle L2 tickets, and 3 handle
                L3 tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If we calculate that 80% of FCR tickets out of 60% of 3000 L1
                tickets is 1440, we can easily assume that L1 agents can take 20
                minutes to close FCR tickets on average, while 4 hours for
                non-FCR tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If per ticket costs $15 for a company, the total costs of 3000
                tickets at tier 1 will be $45,000 monthly, while only an FCR
                ticket costs $21,000 monthly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations are forced to face high ticket handling costs when
                they overlook the necessity of chatbot automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As an organization, you are highly likely to experience
                high-cost ticket management without AI or LLM-powered chatbots
                like Workativ for service desk ticket handling.
              </p>
              <img src={section_8} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s divulge how efficiently businesses can optimize resources
                and drive excellent cost savings for FCR tickets at L1 with
                conversational AI platforms such as Workativ.
              </p>
              <img src={section_9} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                With the Workativ conversational AI chatbot, the average handle
                time per ticket comes down to less than a minute, and the
                average cost per ticket reduces to $3. That means the total cost
                of resolving 1440 tickets comes down to $4,320, resulting in a
                5X cost saving of $17,280 monthly and $207360 annually.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, you can save 3546 agent hours annually.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ's contribution to modernizing support services.
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Employee experience is ultimate. Organizations continue to
                experience productivity issues due to the lack of a powerful yet
                economic digital transformation platform that can provide
                automation to streamline mundane and repetitive manual
                activities for common HR and IT support use cases.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Leveraging the latest in artificial intelligence, such as large
                language models or Generative AI properties, Workativ enables
                businesses to gain hyper-automation for the current business
                functions that are otherwise too mundane and repetitive, causing
                productivity losses and downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ builds resiliency into business processes for
                organizations to become prepared for predictive IT support
                issues and offer instant solutions to get back to work without
                much delay or prolonged downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Password reset is the most common workplace problem that
                consumes a massive productivity time of employees every day.
                Considering the number of apps has shot up, it is tough for
                employees to remember passwords causing them to ask for password
                reset support occasionally.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://www3.weforum.org/docs/WEF_Passwordless_Authentication.pdf">
                  According to a survey,
                </a>{" "}
                there are a couple of findings concerning password resets.
              </p>
              <img src={section_10} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees worldwide spend an average of 11 hours each year
                  entering or resetting their password. For a company of 15,000
                  employees, on average, this represents a direct productivity
                  loss of $5.2 million.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Companies spend 2.5 months on resetting passwords for internal
                  business apps on average.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Again 20%-50% of calls to the IT helpdesk comprise requests
                  regarding password reset. A single reset ranges between $20
                  and $70, which increases IT helpdesk spending per year on
                  staffing or agent utilization.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ helps businesses overcome multiple challenges of
                password resets using app workflow automation and integrations
                with several tools, such as MS Azure AD, Okta, ServiceNow, etc.
                to avoid employee frustrations and improve their satisfaction.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Not just password resets but also many other use cases that
                    HR and IT support have can gain instant autonomous
                    resolutions.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    With a 5X MTTR reduction in request-response delivery,
                    Workativ increases employee satisfaction and makes customer
                    service delivery top-grade.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    With Workativ, businesses can stay calm and have total peace
                    of mind, for they no longer need to worry about platform
                    security and data breaches like ChatGPT exposes risks to
                    business owners.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    The enterprise-grade security features comprising E2E
                    encryption, multifactor authentication, SSL encryption, and
                    malware and spam protection make Workativ a powerful and
                    robust conversational AI and generative AI platform that
                    ensures all employees' details are safe, encouraging rapid
                    adoption for effective change management.
                  </span>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Above the cut, Workativ offers great cost efficiency to improve
                faster adoption to conversational AI technology while allowing
                businesses to leverage the benefits of Generative AI features to
                remain competitive and modernize their workplace for ultimate
                growth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ cost analysis
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Deployment and implementation costs
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a SaaS-based cloud platform. Organizations must take
                care of annual subscription fees, which vary by different plans.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  For the Starter Plan, organizations pay a fee of $1530 billed
                  annually for the deployment of chatbots and implementation of
                  workflow automation.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The Professional Plan costs $2250 to be billed annually with a
                  similar feature to the Starter plan, plus features such as 5
                  agent chat inbox, 4000 chatbot sessions, approval management,
                  app triggers, approval management, bot notifications, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The Enterprise Plan has a custom pricing option. It includes
                  all features of the Professional Plan, plus Knowledge AI,
                  enterprise security, role-based access control, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ also offers a Free Plan for organizations to
                  experiment with the efficacy of an AI-powered bot and
                  visualize the real impacts of automation of mundane work
                  processes.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can benefit from 10% additional cost savings on
                deploying and implementing conversational AI chatbots if they
                pay annually.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can also have a hassle-free implementation of the
                Workativ chatbot. Workativ can help businesses design, build,
                configure, and optimize their chatbot platform. Workativ also
                provides training to improve DIY implementations and deployment.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s ROI has a significant impact on business investment.
                Businesses that lose numerous hours of employee productivity
                incur revenue losses and bottom-line implications.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ delivers effective cost savings over time that
                accumulate to bottom-line savings and revenue growth by allowing
                employees to solve common IT and HR support autonomously.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can also gain the flexibility to leverage the latest
                technology advancements through the Workativ conversational AI
                platform that combines the power of Generative AI, enabling
                businesses to get a competitive edge and gain exceptional
                benefits for workplace automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ can reimagine any business's current state, having not
                explored the power of conversational AI yet. With the app
                workflow automation, Knowledge AI search, and powerful
                integrations, Workativ delivers compelling information discovery
                flexibility, making finding information easy and enhancing the
                employee experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If you want to save on employee turnover and expedite business
                growth, Workativ can help.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. The current state of employee support (challenges)
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Conversational AI X Generative AI-powered chat interface
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Key Features and Capabilities of Workativ
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. The economic impact of Workativ on organizations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Analysis of cost savings
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Workativ's contribution to modernizing support services.
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Workativ cost analysis
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Now and then, employees need information to get work done.
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-future-enterprise-knowledge-search?trk=article-ssr-frontend-pulse_little-text-block">
                  Enterprise search for knowledge discovery
                </a>{" "}
                and access is imperative for businesses of all sizes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service or help desks are most organizations' sole platform for
                finding information. Unfortunately, broken or unsophisticated
                technologies make finding information difficult for users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A legacy{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk
                </a>{" "}
                is at the root of this problem, needing more essential features
                to help with information discovery and access for employees to
                work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise search and knowledge discovery are inconsistent with
                most organizations' standards, forcing employees to struggle
                with the most common IT or HR support issues. So, it is common
                to have operational efficiency and employee disengagement
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ’s conversational AI,
                </a>{" "}
                which combines the features of Generative AI, facilitates faster
                collaborations and communications for knowledge discovery with
                great flexibility and helps improve operations efficiency and
                business resilience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Our eBook emphasizes the economic impact of your investment in
                conversational AI in the long run and better cost optimization,
                which you otherwise fail to realize with the legacy approach,
                incurring higher maintenance costs and productivity losses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To help you understand the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise-pros-cons">
                  {" "}
                  benefits of conversational AI and Generative AI
                </a>{" "}
                for employee productivity and operational efficiency, our
                comprehensive analysis of costs being wasted towards maintaining
                a legacy system gives you a clear understanding of where you are
                spending extra money, yet not leveraging benefits as your
                business needs to grow.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ as a conversational AI is a significant impetus for
                enterprise needs to drive operational efficiency through
                improved employee communications and collaborations, helping
                them find crucial daily information to get work and reduce the
                cost of managing service desks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left mb-10" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                The current state of employee support (challenges)
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee productivity losses and bottom-line implications driven
                by ticketing systems or old and legacy service desk systems are
                often overlooked by business leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses have disparate tools placed at different touchpoints
                for their employees to enable them to raise service or help
                requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Should requests have real-time responses, employees can expect
                rapid resolution of issues and increased uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Incognizance of the efficacy of conversational AI and
                self-service preferences for traditional ITSM tools does not
                help but throw up more challenges.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Inefficient communication results in a 5-hour loss during a
                  40-hour working week. The{" "}
                  <a href="https://www.mitel.com/-/media/mitel/file/pdf/resource-center/en-wp-2019-workplace-productivity-report.pdf">
                    Workplace Productivity and Communications Technology Report
                  </a>{" "}
                  conducted by Mittel and Kelcor in five countries on 2,500
                  business professionals observed that
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Nearly 15% of employees' time at work is wasted in a day,
                  constituting about 1 hour of loss every day and 5 hours a
                  week. The productivity loss occurs for the want of efficient
                  communications systems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Inefficiency in communications costs the employers $10,200
                  annually per worker.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                The cost of employee productivity loss or downtime companies
                experience happens for inefficient tools. It is a double blow on
                the company's balance sheet.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Productivity losses or downtime costs
                </li>
                <li className="font-section-normal-text-testimonials">
                  The cost incurred to maintain multiple communication tools
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                Though the report involves unified communications systems, we
                can relate this to an inefficient service desk or a traditional
                service desk operations management that hinges upon emails,
                phone calls, or intranets. Being multichannel and not
                omnichannel, these disparate tools do not provide integrated
                views of either ticket escalation or lifecycle management,
                causing rising maintenance costs for HR and IT support and
                leading to employee dissatisfaction.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Cost per ticket (CPT) is rising for businesses for service
                  desks unable to handle scalability. It means a legacy model
                  can increase the volume of tickets for incident and service
                  requests when they fail first contact resolution (FCR). It can
                  happen when manual processes are involved in ticket handling.
                  Lack of tools and systems tier 1 agent use may be old and
                  inefficient to handle tickets and causes ticket escalation to
                  the next tier, raising ticket costs.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                In reality, service requests are a lengthy process compared to
                incident requests, which combine to add to a weighted average
                cost per ticket.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd  pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Since employees prefer service requests, an unanswered or
                  delayed service ticket can quickly increase ticket costs and
                  cause productivity losses. ThinkHDI claimed in its report that
                  service requests cost more than incident requests. If
                  employees need to wait for a software upgrade, a new system
                  allocation, and a password reset, a{" "}
                  <a href="https://www.thinkhdi.com/~/media/HDICorp/Files/Library-Archive/Insider%20Articles/Cost%20per%20Ticket.pdf">
                    company has to pay more for a ticket.
                  </a>{" "}
                  For example, if XYZ company handles 60% of service requests
                  and 40% of incident tickets, the weighted average cost per
                  ticket would be higher than the company handling incident
                  tickets in more volumes.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The legacy infrastructure of a service desk model follows a
                  break/fix approach and offers help only when there is a
                  problem. It results in tickets with the same issue being
                  raised to the service desks and agents addressing them
                  repeatedly. From that perspective, a legacy model is more of a
                  reactive platform than a proactive one.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Modern service desks offer self-serve capabilities, yet they
                  are manual and agent-dependent. Even though a modern service
                  desk combines the power of artificial intelligence to allow
                  employees or agents to automate ticket creation, it does not
                  offer self-service help instantly. It is only as good as the
                  legacy service desk that enables employees to create tickets
                  and wait for the agent to hop in and provide help.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The wait time for a ticket increases, causing high costs for
                  each ticket.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-6 list-style-type-square font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The average cost for tickets addressed by voice is $17.19.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chat ticket support costs $15.72 on average.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email support costs $16.13 on average.
                </li>
              </ul>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials pl-4 line-height-18">
                The{" "}
                <a href="https://www.thinkhdi.com/library/supportworld/2021/understanding-cost-per-ticket">
                  figure
                </a>{" "}
                above describes the benchmarking ranges for CPT for North
                American Service Desks.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  If agents for specific channel support are absent, the
                  escalation can move to the next channel support, increasing
                  the costs per ticket.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manual interventions are more likely to involve errors and
                  repetitive ticket handling of the exact service requests,
                  causing productivity losses for agents and employees.
                </li>
              </ul>
              <BlogCta
                ContentCta="Automate your enterprise employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Conversational AI X Generative AI-powered chat interface
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Elimination of manual intervention for service requests and
                fostering operational efficiency at scale
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Manual help for problem-solving can be intimidating because the
                assistance employees want may not turn up on a particular day.
                Unfortunately, it can take another day or two for the help to
                come.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/automated-resolution">
                  Auto-resolutions of service requests
                </a>
                through conversational AI and Generative AI are productive and
                transformative for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A conversational AI and Generative AI solution can define a
                chatbot platform as more sophisticated and advanced than a
                regular chatbot. It gives answers to natural language queries by
                detecting the context and intent of a query rather than
                answering from predefined conversation templates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ redefines workplace automation for common employee
                queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ’s conversational AI combines Generative AI in its
                interface for users to make finding information fast and quick
                without needing additional effort to read through long articles.
                It also provides real-time auto-resolution capabilities for
                users to solve problems instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Companies can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/empowering-it-teams-generative-ai-human-expertise">
                  empower employees
                </a>{" "}
                to surface information regarding common service requests and
                resolve them at scale while ensuring uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Answers to common employee questions such as password reset,
                account unlock, VPN settings, and many more are real-time and
                autonomous for employees who need no human interventions or wait
                for the ticket to escalate to move to the next tier for
                resolution.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee flexibility on Workativ
              </h3>
              <img src={section_2} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  An integrated service desk solution is Workativ’s significant
                  flexibility for employees who no longer need to interact
                  through a service desk platform, create a ticket, and wait for
                  the agent to provide help. Workativ provides{" "}
                  <a href="https://workativ.com/assistant/workflow-automation">
                    app workflow automation
                  </a>{" "}
                  for service desk platforms and allows users to sync with Teams
                  or Slack to auto-resolve issues through LLM-powered Knowledge
                  AI search flexibility or custom FAQs.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employees can have an omnichannel-like compact experience by
                  leveraging a unified conversational AI interface, eliminating
                  the need to seek service requests or help via emails, voice
                  calls, or complicated intranets. Workativ’s conversational AI
                  is the perfect example of a self-serve or autonomous help
                  interface to simplify problem-solving through pre-defined IT
                  or HR support scenarios or custom queries using deep neural
                  networks or large language model search capability.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Seamless knowledge discovery through Knowledge AI integration
                  ensures employees find answers to a question easily on the
                  Workativ Conversational AI interface and can work quickly.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  Change management is fast with Workativ conversational AI that
                  feels more familiar and easier for employees to adapt to a
                  whole new experience and leverage it to capture information
                  for common questions at the workplace. This seamless change
                  management improves employee problem-solving at scale and
                  increases employee productivity.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Organization flexibility with Workativ
              </h3>
              <img src={section_3} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  There are no higher overheads for organizations, for they take
                  advantage of a{" "}
                  <a href="https://workativ.com/">
                    no-code SAAS-based cloud platform,
                  </a>{" "}
                  which requires no additional costs for infrastructure
                  maintenance similar to what is associated with a total cost of
                  ownership.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ encourages faster time to market. Organizations
                  should only consider the right development of conversation
                  flows for HR and IT support use cases, not the implementation
                  and deployment in the live environment. Workativ does not
                  require a steep learning curve, critical technical abilities,
                  or coding skills to work with its conversational or Generative
                  AI platform. The deployment can easily take place with a few
                  details, workflow automation syncs, and integrations.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                    {" "}
                    Seamless app integrations
                  </a>{" "}
                  or app chatbot development for service desk platforms or ITSM
                  tools, Identity Access Management platforms, project
                  management tools, CRM, ERP, etc., are highly effective in
                  creating chatbots for particular apps and take control of
                  functions through familiar collaborations and communications
                  channels.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Ceaseless connections across value chains give organizations
                  the best ability to keep everyone in the loop, such as
                  business leaders, managers, employees, and other stakeholders,
                  and help keep track of how employee productivity is managed,
                  service delivery is taken care of, and what should be the
                  right steps be taken to improve any inefficiency across the
                  touchpoints of employee interaction and information delivery.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Key Features and Capabilities of Workativ
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Exploring Workativ’s cutting-edge technology
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  {" "}
                  Workativ is built on the technological advancement of
                  Artificial Intelligence, Machine Learning, Large Language
                  Models, or Generative AI.
                </a>{" "}
                Workativ is not limited to predefined conversations; it can
                answer any questions.
              </p>
              <img src={section_4} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The conversational AI platform brings natural language
                  processing or NLP engine, chatbot builder, and dialog
                  management modules to help users build complex to simple
                  dialog flow or conversations for employee queries such as
                  printer issues, search documents, unlock accounts, password
                  reset, etc.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-circle pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The NLP module leverages intent detection and entity
                  extraction to efficiently execute tasks or workflows no matter
                  if a user’s NLP query is simple or complicated.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Multiple intent detection can easily handle multiple requests
                  within a single conversation flow.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Auto-corrections and spelling checks let users surface the
                  correct answer even if the query includes spelling errors.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Context switching is more valuable and effective if users want
                  to switch context midway through the conversion and still
                  surface contextual answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Disambiguation helps improve and reduce ambiguity and provide
                  clarity in conversation.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Chatbot automation helps users automate app workflows,
                  integrate with apps, and facilitate approvals. It also allows
                  setting alerts for apps to remind users of necessary actions
                  to perform.
                </li>
                <li className="font-section-normal-text-testimonials">
                  KnowledgeAI provides a large language model capability to help
                  build a{" "}
                  <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-enterprise-fact-check">
                    {" "}
                    ChatGPT
                  </a>
                  -like dialog channel that turns your knowledge into Generative
                  AI answers.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Live agent handover is critical to advancing a conversation
                  when it is repeated or has no answer in its database.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Chatbot analytics and performance give a single pane of glass
                  view into bot session, performance, failed calls, etc.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                All these features and technology advancements have a solid
                focus for Workativ that aims to enrich the user experience by
                improving problem-solving for everyday workplace issues.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The economic impact of Workativ on organizations
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee engagement and cost savings enabled by Workativ
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ's conversational AI platform aims to help businesses
                introduce digital transformation or modernize their current
                processes, which are otherwise unattainable due to the high
                costs associated with tools and technologies and critical
                digital transformation barriers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With a no-code chatbot builder, AI or Generative AI engine,
                prebuilt conversation templates, easy app integrations, and
                other significant features, Workativ helps businesses streamline
                processes through automation, remove manual dependency and
                continuous repetitive task handling and costly mistakes, and
                promote a collaborative culture to build a thriving business by
                enhancing employee experience and engagement and customer
                service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ continues to provide scale to employee support no
                matter how many employees increase through its real-time
                knowledge discovery service for autonomous problem solving for
                businesses. High-tech firms, IT consultancy firms, manufacturing
                firms, sports goods-producing firms, and many others have
                brought efficient and effective modernization to their existing
                processes and automate for true digital transformation at half a
                cost.
              </p>
              <HammerReviewMobile />

              <p className="font-section-normal-text-testimonials line-height-18 ">
                To understand how your investment into Workativ can turn out to
                be effective for your bottom-line investment, analysis of
                benefits and cost-savings is a critical factor.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Quantified benefits{" "}
              </h3>
              <h4 className="font-section-normal-text-medium color-black">
                HR operations benefits
              </h4>
              <img src={section_5} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  5X lower MTTR boosts cost optimization for HR operations. Even
                  a simple request comprising ‘I want to apply for leave’ or ‘I
                  want to know my salary structure’ can take about 20 minutes to
                  4 hours for an HR personnel. Workativ helps you automate most
                  of your employee HR inquiries and requests using an advanced
                  HR Chatbot with in-built HR workflow automation.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees do not need to write to HR. Automated workflows with
                  known and standard HR use cases can be useful for them to ask
                  a direct question to the conversational AI or Generative AI
                  bot from Workativ and get a direct answer.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Simple and fast adoption of the Workativ chatbot allows
                  employees to autonomously reduce common HR requests by
                  themselves and instantly reduce the number of requests to the
                  IT service desks.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-4 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  90% first contact resolution (FCR) saves HR operational costs
                  by restricting the ticket escalation to the next tier, such as
                  level 2 or 3, helping solve HR requests at level 1, and
                  encouraging time-saving of up to 3 hours.
                </li>
              </ul>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd pl-5 list-style-type-circle font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Human resources can save massive resources, freeing senior HR
                  executives from everyday administrative tasks and helping them
                  handle more critical organizational tasks to provide an
                  excellent employee experience.
                </li>
                <li className="font-section-normal-text-testimonials">
                  With the flexibility of self-service functionality, employees
                  can automate their queries and resolve issues, helping HR work
                  more effectively and efficiently driving cost savings and
                  revenue growth.
                </li>
              </ul>
              <h4 className="font-section-normal-text-medium color-black">
                IT operations benefits
              </h4>
              <img src={section_6} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  80% of repetitive IT support can be automated using Workativ
                  conversational AI and Generative AI. Service desk or IT help
                  desk teams can remain calm while addressing critical IT issues
                  and keep themselves out of the most mundane and repetitive
                  common IT issues employees raise. Workativ makes it easy for
                  the service desk or help desk users to handle IT support
                  issues autonomously for service requests such as resetting
                  passwords, unlocking accounts, adding users to groups, asset
                  requests, employee onboarding, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  There are 3 hrs to 3.5 hrs of time savings for organizations.
                  The time-saving experience for IT support teams is similar to
                  that of HR teams with Workativ. The duration of handling every
                  ticket for common IT support requests that take up to 20
                  minutes to 4 hours, as per simplicity or complexity of the
                  ticket’s attributes, can be dramatically reduced to less than
                  1 minute. It means service desk or help desk agents can save
                  time up to 3 hrs - 3.5 hours per ticket.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ allows cost savings of up to 5X for FCR tickets
                  through appropriate optimization of agent utilization. Every
                  minute of time-saving constitutes excellent cost savings for
                  the bottom-line expenses of an organization that predominantly
                  needs to allocate a budget for service desk management. The
                  benefit of Workativ for organizations lies in an appropriate
                  optimization of agent utilization, helping organizations save
                  3546 agent hours annually, which further saves organizations
                  costs up to 5X for FCR tickets.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Service desk agents can achieve 3X productivity boosts while
                  handling employee support issues. Workativ provides
                  flexibility in harnessing Knowledge AI capabilities or
                  Generative AI features to enhance knowledge discovery and help
                  find information at scale autonomously that resolves problems
                  at scale and removes the need to raise tickets to the agents.
                  This gives agents and employees a 3X boost in productivity by
                  reducing downtime impacts.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <a href="https://workativ.com/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                  {" "}
                  Workativ has collaborated with GoTo,
                </a>{" "}
                an IT and software solutions provider company, to help transform
                their employee support through conversational AI and Generative
                AI helpdesk automation chatbot.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
              <h3 className="font-section-sub-header-small-home">
                Unquantified benefits
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Increased employee engagement and productivity gains are two
                  of the key unquantified benefits of Workativ for
                  organizations. Employees are more at ease in solving their
                  problems and less likely to involve a service desk agent in
                  solving common and everyday IT support issues. Employees no
                  longer need to suffer productivity issues of system downtime
                  software upgrade requirements, etc. Similarly, service desk
                  agents can have more time to focus on critical business
                  objectives such as handling IT operations, asset management,
                  etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Everyone at work can seamlessly gain elevated user experience.
                  It’s an amazing experience for value chains across every
                  department. Employees can get an answer easily using advanced
                  LLM-powered knowledge discovery in the service desk automation
                  chatbot and continue their work progress. Agents effortlessly
                  use LLM-powered chatbots to see conversation history and gain
                  context to add personalization to users’ IT or HR support
                  issues. Also, business leaders in their high-level leadership
                  roles, such as CEO, CFO, CIO, or senior-level executives, gain
                  business benefits through employee productivity gains that
                  bring excellent customer service delivery and gain customer
                  trust.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ offers multimodal support for all departments beyond
                  HR, and IT use cases. Finance, Legal, Operations, Marketing,
                  and Sales can derive workflow automation benefits for a wide
                  variety of use cases to remove manual dependency and
                  streamline work processes through automation.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Analysis of cost savings
              </h2>
              <h3 className="font-section-sub-header-small-home">
                Comparing ROI with and without Workativ
              </h3>
              <img src={section_7} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say the total headcount of your organization is 3000 and the
                number of monthly tickets is 5000. Out of 20 service desk
                agents, 10 handle L1 tickets, 7 handle L2 tickets, and 3 handle
                L3 tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If we calculate that 80% of FCR tickets out of 60% of 3000 L1
                tickets is 1440, we can easily assume that L1 agents can take 20
                minutes to close FCR tickets on average, while 4 hours for
                non-FCR tickets.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If per ticket costs $15 for a company, the total costs of 3000
                tickets at tier 1 will be $45,000 monthly, while only an FCR
                ticket costs $21,000 monthly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Organizations are forced to face high ticket handling costs when
                they overlook the necessity of chatbot automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As an organization, you are highly likely to experience
                high-cost ticket management without AI or LLM-powered chatbots
                like Workativ for service desk ticket handling.
              </p>
              <img src={section_8} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s divulge how efficiently businesses can optimize resources
                and drive excellent cost savings for FCR tickets at L1 with
                conversational AI platforms such as Workativ.
              </p>
              <img src={section_9} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                With the Workativ conversational AI chatbot, the average handle
                time per ticket comes down to less than a minute, and the
                average cost per ticket reduces to $3. That means the total cost
                of resolving 1440 tickets comes down to $4,320, resulting in a
                5X cost saving of $17,280 monthly and $207360 annually.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On top of it, you can save 3546 agent hours annually.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ's contribution to modernizing support services.
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Employee experience is ultimate. Organizations continue to
                experience productivity issues due to the lack of a powerful yet
                economic digital transformation platform that can provide
                automation to streamline mundane and repetitive manual
                activities for common HR and IT support use cases.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Leveraging the latest in artificial intelligence, such as large
                language models or Generative AI properties, Workativ enables
                businesses to gain hyper-automation for the current business
                functions that are otherwise too mundane and repetitive, causing
                productivity losses and downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ builds resiliency into business processes for
                organizations to become prepared for predictive IT support
                issues and offer instant solutions to get back to work without
                much delay or prolonged downtime.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Password reset is the most common workplace problem that
                consumes a massive productivity time of employees every day.
                Considering the number of apps has shot up, it is tough for
                employees to remember passwords causing them to ask for password
                reset support occasionally.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="https://www3.weforum.org/docs/WEF_Passwordless_Authentication.pdf">
                  According to a survey,
                </a>{" "}
                there are a couple of findings concerning password resets.
              </p>
              <img src={section_10} className="blog_image_top_bt" />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Employees worldwide spend an average of 11 hours each year
                  entering or resetting their password. For a company of 15,000
                  employees, on average, this represents a direct productivity
                  loss of $5.2 million.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Companies spend 2.5 months on resetting passwords for internal
                  business apps on average.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Again 20%-50% of calls to the IT helpdesk comprise requests
                  regarding password reset. A single reset ranges between $20
                  and $70, which increases IT helpdesk spending per year on
                  staffing or agent utilization.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ helps businesses overcome multiple challenges of
                password resets using app workflow automation and integrations
                with several tools, such as MS Azure AD, Okta, ServiceNow, etc.
                to avoid employee frustrations and improve their satisfaction.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd list-style-type-none pl-4 font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Not just password resets but also many other use cases that
                    HR and IT support have can gain instant autonomous
                    resolutions.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    With a 5X MTTR reduction in request-response delivery,
                    Workativ increases employee satisfaction and makes customer
                    service delivery top-grade.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    With Workativ, businesses can stay calm and have total peace
                    of mind, for they no longer need to worry about platform
                    security and data breaches like ChatGPT exposes risks to
                    business owners.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>
                    The enterprise-grade security features comprising E2E
                    encryption, multifactor authentication, SSL encryption, and
                    malware and spam protection make Workativ a powerful and
                    robust conversational AI and generative AI platform that
                    ensures all employees' details are safe, encouraging rapid
                    adoption for effective change management.
                  </span>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Above the cut, Workativ offers great cost efficiency to improve
                faster adoption to conversational AI technology while allowing
                businesses to leverage the benefits of Generative AI features to
                remain competitive and modernize their workplace for ultimate
                growth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Workativ cost analysis
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Deployment and implementation costs
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a SaaS-based cloud platform. Organizations must take
                care of annual subscription fees, which vary by different plans.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  For the Starter Plan, organizations pay a fee of $1530 billed
                  annually for the deployment of chatbots and implementation of
                  workflow automation.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The Professional Plan costs $2250 to be billed annually with a
                  similar feature to the Starter plan, plus features such as 5
                  agent chat inbox, 4000 chatbot sessions, approval management,
                  app triggers, approval management, bot notifications, etc.
                </li>
                <li className="font-section-normal-text-testimonials">
                  The Enterprise Plan has a custom pricing option. It includes
                  all features of the Professional Plan, plus Knowledge AI,
                  enterprise security, role-based access control, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Workativ also offers a Free Plan for organizations to
                  experiment with the efficacy of an AI-powered bot and
                  visualize the real impacts of automation of mundane work
                  processes.
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can benefit from 10% additional cost savings on
                deploying and implementing conversational AI chatbots if they
                pay annually.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can also have a hassle-free implementation of the
                Workativ chatbot. Workativ can help businesses design, build,
                configure, and optimize their chatbot platform. Workativ also
                provides training to improve DIY implementations and deployment.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ’s ROI has a significant impact on business investment.
                Businesses that lose numerous hours of employee productivity
                incur revenue losses and bottom-line implications.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ delivers effective cost savings over time that
                accumulate to bottom-line savings and revenue growth by allowing
                employees to solve common IT and HR support autonomously.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Businesses can also gain the flexibility to leverage the latest
                technology advancements through the Workativ conversational AI
                platform that combines the power of Generative AI, enabling
                businesses to get a competitive edge and gain exceptional
                benefits for workplace automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ can reimagine any business's current state, having not
                explored the power of conversational AI yet. With the app
                workflow automation, Knowledge AI search, and powerful
                integrations, Workativ delivers compelling information discovery
                flexibility, making finding information easy and enhancing the
                employee experience.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If you want to save on employee turnover and expedite business
                growth, Workativ can help.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              Conversational AI: The Next Frontier in Enterprise Collaboration
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies"
              className="font-section-normal-text-testimonials"
            >
              Generative AI for Dummies ( what, how, why for companies)
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
function HammerReviewMobile() {
  return (
    <>
      <div className="testimonial_feedback_box_mobile box-shadow-none home_assistant_tesimonials_content_mobile border-solid-grey mb-4">
        <div className="testimonial_feedback_top_content_box_mobile">
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            <span>
              <img
                src={quotes}
                className="quotes_image_testimonial h-auto"
              ></img>
            </span>
            The setup and building of the Workativ Chatbot I felt was extremely
            well supported! My questions were answered quickly and at no point
            did I feel unsupported. I had multiple Zoom meetings through the
            process where we would meet to go through the build.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18">
            When the build hit a hurdle, the resolution was found and
            implemented within 24-48 hours. Through this communication was
            excellent.
          </p>
          <p className="font-section-normal-text-testimonials text-align-left line-height-18 mb-2">
            I felt listened through what we absolutely had to have for this tool
            to be rolled out in our environment. Throughout the process we kept
            looking back at the business requirements to make sure we were
            aligning properly.
          </p>
        </div>

        <img
          src={hammer_manager_mobile}
          className="testimonial_reviewer_image h-auto"
          alt="hammer_global_manager"
          style={{ width: "80px" }}
        ></img>

        <div className="bottom_designation_box_right_mobile ">
          <h5
            className="font-section-sub-header-smaller-bold-testimonials mt-3"
            style={{ color: "#4F75D9" }}
          >
            Dan Bottiger
          </h5>
          <h5 className="font-section-normal-text-medium reviewer_designation_hammer">
            Director of Information Systems
          </h5>
          <img src={hammer_logo} className="w-35 h-auto"></img>
        </div>
        <button
          className="goto_case_study_button_redirection w-100 mt-3"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </>
  );
}
function HammerReview() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <div className="main_container_testimonials_usecase testimonials_container_support w-100 mb-4 border-solid-grey box-shadow-none">
      <div className="top_image_container_testimonials_usecase top_image_container_testimonials_it_support">
        <img
          loading="lazy"
          className="capterra_logo_left capterra_usecase"
          src={capterra_logo}
        />
        <img
          loading="lazy"
          src={star_image}
          className="rating_star_image"
        ></img>
        <img
          loading="lazy"
          src={star_image}
          className="rating_star_image"
        ></img>
        <img
          loading="lazy"
          src={star_image}
          className="rating_star_image"
        ></img>
        <img
          loading="lazy"
          src={star_image}
          className="rating_star_image"
        ></img>
        <img
          loading="lazy"
          src={star_image}
          className="rating_star_image"
        ></img>
        <span>5/5</span>
      </div>
      <div className="middle_content_container_testimonials_usecase">
        <h5 className="font-section-sub-header">
          “Workativ delivers and the implementation team is top notch. Very
          supportive and responsive to question”
        </h5>
        <p className="font-section-normal-text-testimonials">
          <span className="font-section-normal-text-medium color-black">
            Overall:&nbsp;
          </span>
          Very positive experience. We are able to grow the bot based on our
          internal needs. The cost and support can’t be beat for all the
          features the tool provides
        </p>
        <p className="font-section-normal-text-testimonials line-height-18">
          <span className="font-section-normal-text-medium color-black">
            Pros:&nbsp;
          </span>
          It just seems to work out of the box. If you have a decent outline of
          the needs the bot needs to fill it is really straightforward to set up
          and test. Their deployment team offers insight on where to focus time
          and provides resources to go to quickly resolve questions on your own.
          Truly self service.
        </p>
        <p class="font-section-normal-text-testimonials line-height-18">
          <span className="font-section-normal-text-medium color-black">
            Cons:&nbsp;
          </span>
          No complaints right now - when we have had issues they respond quickly
          with a plan and their follow through is excellent
        </p>
        <p class="font-section-normal-text-testimonials line-height-18 mb-0">
          <span className="font-section-normal-text-medium color-black">
            Reasons for Choosing Workativ Assistant:&nbsp;
          </span>
          Cost and the willingness of Workativ to answer questions and ensure
          our internal business requirements were being met.
        </p>
      </div>
      <div className="bottom_button_content_container_testimonials">
        <div className="bottom_reviewer_image_container_testimonials_usecase">
          <img
            loading="lazy"
            src={reviewer_image}
            className="testimonial_reviewer_image_landing_page"
            alt="hammer_manager"
          ></img>
          <div className="end_name_usecase">
            <h5
              className="font-section-sub-header-bold-goto-case hammer_dir_name mb-0"
              style={{ color: "#4F75D9" }}
            >
              Dan Bottiger
            </h5>
            <h5 className="font-section-normal-text-medium reviewer_designation_hammer mb-0">
              Director of Information Systems
            </h5>

            <img
              src={hammer_logo}
              className="it_support_hammer_logo h-auto"
            ></img>
          </div>
        </div>
        <button
          className="goto_case_study_button_redirection w-40 mt-3 h-fit-content"
          onClick={() => {
            window.location.href =
              "/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation";
          }}
        >
          <span className="font-section-normal-text-medium">
            Explore success stories
          </span>
        </button>
      </div>
    </div>
  );
}
